import React, { useRef, useEffect, useState } from 'react';
import { AlertCircle, Lightbulb, ShieldAlert, AlertTriangle, AlertOctagon } from 'lucide-react';

const testimonials = [
  {
    icon: <AlertCircle className="h-6 w-6 text-red-500" />,
    name: "Sarah Thompson",
    title: "Mother of Two",
    quote: "I was shocked to find artificial colors and high fructose corn syrup in my kids' 'all-natural' fruit snacks. They were marketed as healthy but contained more sugar than candy!",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&q=80&w=2000",
    product: "Fruit Snacks",
    discovery: "High Fructose Corn Syrup"
  },
  {
    icon: <Lightbulb className="h-6 w-6 text-amber-500" />,
    name: "Michael Rodriguez",
    title: "Father of Three",
    quote: "We thought granola bars were a healthy choice for after-school snacks. Turns out they had more added sugar and processed oils than a chocolate chip cookie!",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?auto=format&fit=crop&q=80&w=2000",
    product: "Granola Bars",
    discovery: "Added Sugars"
  },
  {
    icon: <ShieldAlert className="h-6 w-6 text-purple-500" />,
    name: "Emily Chen",
    title: "Mother of One",
    quote: "My daughter's favorite yogurt was marketed as 'brain food' but contained artificial sweeteners and preservatives that can affect behavior and focus.",
    image: "https://images.unsplash.com/photo-1489424731084-a5d8b219a5bb?auto=format&fit=crop&q=80&w=2000",
    product: "Children's Yogurt",
    discovery: "Artificial Sweeteners"
  },
  {
    icon: <AlertTriangle className="h-6 w-6 text-orange-500" />,
    name: "David Wilson",
    title: "Father of Two",
    quote: "The 'whole grain' breakfast cereal we've been buying actually has more processed ingredients and added sugars than nutrients. It was eye-opening to learn what's really in it.",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?auto=format&fit=crop&q=80&w=2000",
    product: "Breakfast Cereal",
    discovery: "Hidden Sugars"
  },
  {
    icon: <AlertOctagon className="h-6 w-6 text-red-500" />,
    name: "Lisa Martinez",
    title: "Mother of Three",
    quote: "I discovered that our kids' favorite juice boxes, labeled as '100% natural', contained artificial flavors and more sugar than soda. We've switched to water and fresh fruit instead.",
    image: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?auto=format&fit=crop&q=80&w=2000",
    product: "Juice Boxes",
    discovery: "Artificial Flavors"
  }
];

export default function Testimonials() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const cardWidth = container.querySelector('.testimonial-card')?.clientWidth || 0;
      const gap = 24; // 6 * 4 (gap-6 class)
      const scrollAmount = cardWidth + gap;

      let newIndex = direction === 'right' ? currentIndex + 1 : currentIndex - 1;

      // Handle circular scrolling
      if (newIndex >= testimonials.length) {
        newIndex = 0;
        container.scrollTo({ left: 0, behavior: 'smooth' });
      } else if (newIndex < 0) {
        newIndex = testimonials.length - 1;
        container.scrollTo({ left: container.scrollWidth, behavior: 'smooth' });
      } else {
        container.scrollBy({ left: direction === 'right' ? scrollAmount : -scrollAmount, behavior: 'smooth' });
      }

      setCurrentIndex(newIndex);
    }
  };

  // Handle scroll snap end
  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;

    const handleScroll = () => {
      const cardWidth = container.querySelector('.testimonial-card')?.clientWidth || 0;
      const gap = 24;
      const scrollPosition = container.scrollLeft;
      const newIndex = Math.round(scrollPosition / (cardWidth + gap));
      setCurrentIndex(newIndex);
    };

    container.addEventListener('scrollend', handleScroll);
    return () => container.removeEventListener('scrollend', handleScroll);
  }, []);

  return (
    <section className="py-24 bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-0 w-64 h-64 bg-red-50 rounded-full blur-3xl opacity-30" />
        <div className="absolute bottom-0 right-0 w-64 h-64 bg-amber-50 rounded-full blur-3xl opacity-30" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-16">
          <span className="text-gray-900 font-medium">Reviews</span>
          <h2 className="mt-2 text-3xl md:text-4xl font-bold text-gray-900">
            What other <span className="text-emerald-600">parents</span> are saying
          </h2>
        </div>

        {/* Scroll Container */}
        <div className="relative">
          {/* Scroll Buttons */}
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
          >
            <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
          >
            <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>

          {/* Scrollable Container */}
          <div 
            ref={scrollRef}
            className="flex overflow-x-auto gap-6 pb-4 snap-x snap-mandatory hide-scrollbar"
          >
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                {...testimonial}
              />
            ))}
          </div>

          {/* Dots Navigation */}
          <div className="flex justify-center gap-2 mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  if (scrollRef.current) {
                    const cardWidth = scrollRef.current.querySelector('.testimonial-card')?.clientWidth || 0;
                    const gap = 24;
                    scrollRef.current.scrollTo({
                      left: index * (cardWidth + gap),
                      behavior: 'smooth'
                    });
                    setCurrentIndex(index);
                  }
                }}
                className={`w-2 h-2 rounded-full transition-all ${
                  index === currentIndex
                    ? 'bg-emerald-600 w-4'
                    : 'bg-gray-300 hover:bg-gray-400'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function TestimonialCard({
  icon,
  name,
  title,
  quote,
  image,
  product,
  discovery
}: {
  icon: React.ReactNode;
  name: string;
  title: string;
  quote: string;
  image: string;
  product: string;
  discovery: string;
}) {
  return (
    <div className="testimonial-card flex-shrink-0 w-full md:w-[350px] snap-center">
      <div className="bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow border border-gray-100 overflow-hidden h-full">
        <div className="p-6">
          <div className="flex items-center gap-4 mb-6">
            <img
              src={image}
              alt={name}
              className="w-12 h-12 rounded-full object-cover"
            />
            <div>
              <h3 className="font-semibold text-gray-900">{name}</h3>
              <p className="text-sm text-gray-600">{title}</p>
            </div>
          </div>

          <blockquote className="text-gray-600 mb-6">
            "{quote}"
          </blockquote>

          <div className="bg-red-50 rounded-xl p-4">
            <div className="flex items-start gap-3">
              {icon}
              <div>
                <h4 className="font-medium text-gray-900">{product}</h4>
                <p className="text-sm text-red-600 mt-1">
                  Hidden Ingredient: {discovery}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}