import { supabase } from '../lib/supabase';
import type { Database } from '../types/supabase';

type UserProfile = Database['public']['Tables']['user_preferences']['Row'];

export async function getUserProfile(userId: string) {
  const { data, error } = await supabase
    .from('user_preferences')
    .select('*')
    .eq('user_id', userId)
    .single();

  if (error && error.code !== 'PGRST116') { // PGRST116 is "not found"
    throw error;
  }

  return data;
}

export async function createUserProfile(userId: string, profile: Partial<UserProfile>) {
  const { data, error } = await supabase
    .from('user_preferences')
    .insert({
      user_id: userId,
      allergen_alerts: profile.allergen_alerts || [],
      dietary_restrictions: profile.dietary_restrictions || [],
      notification_preferences: profile.notification_preferences || { email: true, push: false }
    })
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}

export async function updateUserProfile(userId: string, profile: Partial<UserProfile>) {
  const { data, error } = await supabase
    .from('user_preferences')
    .update(profile)
    .eq('user_id', userId)
    .select()
    .single();

  if (error) {
    throw error;
  }

  return data;
}

// Define the ChildProfile type to match your database schema
export interface ChildProfile {
  id: string;
  user_id: string;
  parent_name: string;
  name: string;
  age: number;
  allergen_alerts: string[];
  dietary_restrictions: string[];
  health_concerns: string[];
  activity_level: 'low' | 'moderate' | 'high' | null;
  created_at: string;
  updated_at: string;
}

export async function getChildProfiles(userId: string): Promise<ChildProfile[]> {
  // Using any here temporarily until you update your database types
  const { data, error } = await supabase
    .from('child_profiles')
    .select('*')
    .eq('user_id', userId) as { data: ChildProfile[] | null, error: any };

  if (error) {
    console.error('Error fetching child profiles:', error);
    throw error;
  }

  return data || [];
}

// Add a function to create child profiles
export async function createChildProfile(userId: string, childInfo: {
  parent_name: string;
  name: string;
  age: number;
  activity_level: 'low' | 'moderate' | 'high';
  dietary_restrictions: string[];
  health_concerns: string[];
  allergen_alerts?: string[];
}) {
  const { data, error } = await supabase
    .from('child_profiles')
    .insert({
      user_id: userId,
      parent_name: childInfo.parent_name,
      name: childInfo.name,
      age: childInfo.age,
      activity_level: childInfo.activity_level,
      dietary_restrictions: childInfo.dietary_restrictions,
      health_concerns: childInfo.health_concerns,
      allergen_alerts: childInfo.allergen_alerts || [],
    })
    .select()
    .single();

  if (error) {
    console.error('Error creating child profile:', error);
    throw error;
  }

  return data;
} 