export default function Hero() {
  return (
    <div className="relative bg-white overflow-hidden min-h-[85vh]">
      {/* SVG Background Pattern */}
      <div className="absolute inset-0 z-0">
        {/* Dotted Pattern */}
        <svg
          className="absolute inset-0 w-full h-full"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <pattern
            id="dotPattern"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <circle cx="2" cy="2" r="1" fill="#E5E7EB" />
          </pattern>
          <rect width="100%" height="100%" fill="url(#dotPattern)" />
        </svg>

        {/* Gradient Circle */}
        <svg
          className="absolute right-0 top-0 h-full w-1/2 transform translate-x-1/3"
          width="100%"
          height="100%"
          viewBox="0 0 800 800"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMinYMin slice"
        >
          <circle cx="400" cy="400" r="400" fill="url(#gradient)" fillOpacity="0.1" />
          <defs>
            <radialGradient
              id="gradient"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(400 400) rotate(90) scale(400)"
            >
              <stop stopColor="#059669" />
              <stop offset="1" stopColor="#059669" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <div className="relative z-10 min-h-[85vh] flex items-center">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="grid lg:grid-cols-2 gap-12 items-center mt-12 sm:mt-0">
            {/* Left Column - Text Content */}
            <div>
              <h1 className="text-5xl md:text-6xl lg:text-7xl font-serif tracking-tight">
                Catch harmful ingredients {' '}
                <span className="text-emerald-600 block">before your child does</span>
              </h1>
              <p className="mt-6 text-xl text-gray-600 max-w-2xl">
                Scan any food barcode to get personalized recommendations for your child's unique health needs
              </p>
              <div className="mt-10 flex flex-wrap justify-center lg:justify-start gap-4">
                <button 
                  onClick={() => {
                    const element = document.querySelector('section');
                    const offset = 30; // 10 pixels offset
                    const elementPosition = element?.getBoundingClientRect().top ?? 0;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    
                    window.scrollTo({
                      top: offsetPosition,
                      behavior: 'smooth'
                    });
                  }}
                  className="bg-emerald-600 text-white px-10 py-4 rounded-full hover:bg-emerald-700 
                    transition-all text-lg font-medium shadow-lg hover:shadow-xl 
                    hover:scale-105 transform duration-200"
                >
                  Start Scanning
                </button>
              </div>
            </div>

            {/* Right Column - Image Grid */}
            <div className="relative grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <img
                  src="https://images.unsplash.com/photo-1546069901-ba9599a7e63c"
                  alt="Healthy bowl"
                  className="w-full h-64 object-cover rounded-3xl shadow-lg"
                />
                <img
                  src="https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd"
                  alt="Food spread"
                  className="w-full h-48 object-cover rounded-3xl shadow-lg"
                />
              </div>
              <div className="mt-8 space-y-4">
                <img
                  src="https://images.unsplash.com/photo-1547592180-85f173990554"
                  alt="Healthy breakfast"
                  className="w-full h-48 object-cover rounded-3xl shadow-lg"
                />
                <img
                  src="https://images.unsplash.com/photo-1512621776951-a57141f2eefd"
                  alt="Vegetable dish"
                  className="w-full h-64 object-cover rounded-3xl shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}