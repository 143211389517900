import React, { useState, useEffect } from 'react';
import { Search, Info } from 'lucide-react';
import ProductPopup from './ProductPopup';
import { preloadExampleProducts } from '../services/foodApi';

export default function BarcodeInput() {
  const [barcode, setBarcode] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState('');

  // Preload example products on component mount
  useEffect(() => {
    preloadExampleProducts();
  }, []);

  const validateBarcode = (code: string) => {
    const cleanCode = code.trim();
    if (!cleanCode) {
      return 'Please enter a barcode number';
    }
    if (!/^\d+$/.test(cleanCode)) {
      return 'Barcode should only contain numbers';
    }
    if (cleanCode.length < 8 || cleanCode.length > 13) {
      return 'Barcode should be between 8 and 13 digits';
    }
    return '';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validationError = validateBarcode(barcode);
    if (validationError) {
      setError(validationError);
      return;
    }
    setError('');
    setShowPopup(true);
  };

  const exampleBarcodes = [
    { code: '044000032029', name: 'Lunchables Turkey & Cheddar' },
    { code: '810116121557', name: 'PRIME Hydration' },
    { code: '028400090605', name: 'Veggie Straws' },
  ];

  return (
    <section className="py-12 bg-white">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-900">
            Know Your Food
          </h2>
          <p className="mt-2 text-gray-600">
            Enter a barcode number to see detailed product information
          </p>
        </div>

        <form onSubmit={handleSubmit} className="max-w-xl mx-auto">
          <div className="flex gap-3">
            <div className="flex-1">
              <input
                type="text"
                value={barcode}
                onChange={(e) => {
                  setBarcode(e.target.value);
                  setError('');
                }}
                placeholder="Enter barcode number..."
                className={`w-full px-4 py-3 rounded-lg border ${
                  error ? 'border-red-300 focus:ring-red-500' : 'border-gray-300 focus:ring-emerald-500'
                } focus:ring-2 focus:border-transparent outline-none transition-all`}
              />
              {error && (
                <p className="mt-2 text-sm text-red-600">{error}</p>
              )}
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors flex items-center gap-2 group"
            >
              <Search className="h-5 w-5 group-hover:scale-110 transition-transform" />
              Search
            </button>
          </div>
        </form>

        {/* Example Barcodes */}
        <div className="mt-8">
          <div className="flex items-center gap-2 justify-center text-sm text-gray-600 mb-3">
            <Info className="h-4 w-4" />
            <span>Try these example barcodes:</span>
          </div>
          <div className="flex flex-wrap gap-2 justify-center">
            {exampleBarcodes.map((example) => (
              <button
                key={example.code}
                onClick={() => {
                  setBarcode(example.code);
                  setError('');
                }}
                className="px-3 py-1 text-sm bg-gray-50 hover:bg-gray-100 text-gray-700 rounded-full border border-gray-200 transition-colors"
              >
                {example.name} ({example.code})
              </button>
            ))}
          </div>
        </div>

        <ProductPopup
          isOpen={showPopup}
          onClose={() => setShowPopup(false)}
          barcode={barcode}
        />
      </div>
    </section>
  );
}