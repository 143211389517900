import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from './useAuth';

export function useCustomerData() {
  const { user } = useAuth();
  const [fullName, setFullName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchCustomerData() {
      if (!user?.id) return;

      try {
        const { data, error } = await supabase
          .from('stripe_customers')
          .select('full_name')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;
        setFullName(data?.full_name || null);
      } catch (error) {
        console.error('Error fetching customer data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchCustomerData();
  }, [user]);

  return { fullName, isLoading };
} 