import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles } from 'lucide-react';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { useSubscription } from '../hooks/useSubscription';

interface ChildInfo {
  parentName: string;
  name: string;
  age: string;
  activityLevel: string;
  dietaryRestrictions: string[];
  healthConcerns: string[];
}

interface Props {
  childInfo: ChildInfo;
}

export default function ChildProfileSummary({ childInfo }: Props) {
  const navigate = useNavigate();
  const { isSubscribed, isLoading: isCheckingSubscription } = useSubscription();
  const [summary, setSummary] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    generateSummary();
  }, []);

  const generateSummary = async () => {
    setIsLoading(true);
    try {
      const prompt = `You are BarcodeBites, a confident and knowledgeable AI food scanning assistant. Write a personalized welcome message for ${childInfo.name} (${childInfo.age} years old) and their parent ${childInfo.parentName}.

Key Profile Information:
- Activity Level: ${childInfo.activityLevel}
${childInfo.dietaryRestrictions.length > 0 
  ? `- Dietary Needs: ${childInfo.dietaryRestrictions.join(', ')}` 
  : ''}
${childInfo.healthConcerns.length > 0 
  ? `- Health Considerations: ${childInfo.healthConcerns.join(', ')}` 
  : ''}

Structure your response exactly like this:

Welcome ${childInfo.parentName} and ${childInfo.name}! We're excited to help you make informed food choices that perfectly match ${childInfo.name}'s needs.

BarcodeBites will:
• [First specific solution tied to child's profile - focus on solving a key challenge]
• [Second specific solution tied to child's profile - focus on making their life easier]
• [Third specific solution tied to child's profile - focus on health benefits]


Ready to start finding the perfect foods for ${childInfo.name}? Let's begin!

Use **bold** for emphasis on key points. Keep the tone energetic while focusing on practical solutions.`;

      const genAI = new GoogleGenerativeAI(import.meta.env.VITE_GEMINI_API_KEY);
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });

      const result = await model.generateContent(prompt);
      const response = result.response;
      const text = response.text();
      
      setSummary(text);
    } catch (error) {
      console.error('Error generating summary:', error);
      setSummary(`
Welcome ${childInfo.parentName} and ${childInfo.name}! We're excited to help you make informed food choices that perfectly match ${childInfo.name}'s needs.

BarcodeBites will:
• **Instantly analyze ingredients** to ensure they align with your dietary preferences
• **Provide clear, actionable insights** about each food product you scan
• **Recommend alternatives** when a product doesn't meet ${childInfo.name}'s needs

Ready to start finding the perfect foods for ${childInfo.name}? Let's begin!
      `);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetStarted = () => {
    if (isSubscribed) {
      navigate('/scanner');
    } else {
      navigate('/pricing');
    }
  };

  // Add this function to format markdown bold text
  const formatMarkdownBold = (text: string) => {
    // First handle bold text
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Then handle numbered points by splitting into lines
    let pointNumber = 1;
    formattedText = formattedText.split('\n').map(line => {
      // If line starts with • (bullet point), replace with number
      if (line.trim().startsWith('•')) {
        return `<div class="flex gap-2 mt-2">
          <span class="flex-shrink-0 font-medium">${pointNumber++}.</span>
          <span>${line.trim().substring(1).trim()}</span>
        </div>`;
      }
      // Add paragraph tags to non-bullet lines that aren't empty
      return line.trim() ? `<p class="mb-4">${line.trim()}</p>` : '';
    }).join('');

    return formattedText;
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4 mb-6">
        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
          <Sparkles className="w-6 h-6 text-emerald-600" />
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Your Personalized Food Guide</h2>
          <p className="text-gray-600">Here's how we can help {childInfo.name} eat better</p>
        </div>
      </div>

      {isLoading ? (
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
      ) : (
        <>
          <div className="prose prose-emerald max-w-none">
            <div className="bg-emerald-50 rounded-xl p-6"
              dangerouslySetInnerHTML={{ __html: formatMarkdownBold(summary) }}
            />
          </div>

          <div className="mt-8 space-y-4">
            <button
              onClick={handleGetStarted}
              disabled={isCheckingSubscription}
              className="w-full flex items-center justify-center gap-2 px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50"
            >
              {isCheckingSubscription ? 'Loading...' : "Let's get started!"}
            </button>
          </div>
        </>
      )}
    </div>
  );
} 