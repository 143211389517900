import { useState } from 'react';
import { CreditCard, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';

export default function AdvancedSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePortalSession = async () => {
    try {
      setIsLoading(true);
      
      // Get current session and access token
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        console.error('No valid session found');
        navigate('/login');
        return;
      }

      // Update the URL to point to the Edge Function
      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-portal-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          returnUrl: `${window.location.origin}/settings`
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Portal session error:', {
          status: response.status,
          text: errorText
        });
        
        if (response.status === 401) {
          // Try to refresh the session
          const { data: { session: newSession }, error: refreshError } = await supabase.auth.refreshSession();
          if (refreshError || !newSession) {
            console.error('Session refresh failed:', refreshError);
            await supabase.auth.signOut();
            navigate('/login');
            return;
          }
          // Retry the request with the new token
          return handlePortalSession();
        }
        
        throw new Error(`Failed to create portal session: ${errorText}`);
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to access billing portal. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-6">Advanced Settings</h2>
      
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">Billing & Subscription</h3>
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800">
            Pro Plan
          </span>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-4">
            <div className="flex-shrink-0">
              <CreditCard className="h-5 w-5 text-gray-400" />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900">Payment Method</p>
              <p className="text-sm text-gray-500">Visa ending in 4242</p>
            </div>
            <button 
              onClick={handlePortalSession}
              className="text-xs text-emerald-600 hover:text-emerald-700 font-medium"
            >
              Update
            </button>
          </div>

          <div className="flex items-center gap-4">
            <div className="flex-shrink-0">
              <Shield className="h-5 w-5 text-gray-400" />
            </div>
            <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900">Billing History</p>
              <p className="text-sm text-gray-500">Next billing date: April 1, 2024</p>
            </div>
            <button 
              onClick={handlePortalSession}
              className="text-xs text-emerald-600 hover:text-emerald-700 font-medium"
            >
              View
            </button>
          </div>
        </div>

        <div className="pt-4">
          <button
            onClick={handlePortalSession}
            disabled={isLoading}
            className="text-xs text-gray-600 hover:text-gray-900 disabled:opacity-50"
          >
            {isLoading ? 'Loading...' : 'View plan details'}
          </button>
        </div>
      </div>
    </div>
  );
} 