import React from 'react';
import { Smartphone, Scan, ShieldCheck, ArrowRight } from 'lucide-react';

export default function HowItWorks() {
  return (
    <section className="py-16 bg-gradient-to-b from-white to-gray-50 relative overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-1/2 -left-16 w-32 h-32 bg-emerald-100 rounded-full blur-3xl opacity-60" />
        <div className="absolute bottom-0 right-0 w-48 h-48 bg-emerald-50 rounded-full blur-3xl opacity-60" />
      </div>

      <div className="max-w-5xl mx-auto px-8 sm:px-12 lg:px-16 relative">
        <div className="text-center mb-12 md:mb-16">
          <span className="text-gray-900 font-medium">How It Works</span>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mt-3">
            Three easy steps to <span className="text-emerald-600">instantly</span> protect your family
          </h2>
        </div>

        <div className="relative">
          {/* Connection Line - Only visible on larger screens */}
          <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-emerald-100 -translate-y-1/2 hidden lg:block" />

          <div className="grid gap-8 md:gap-12 lg:grid-cols-3 lg:gap-16">
            {steps.map((step, index) => (
              <Step
                key={step.title}
                number={index + 1}
                icon={step.icon}
                title={step.title}
                description={step.description}
                isLast={index === steps.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const steps = [
  {
    icon: <Smartphone className="h-6 w-6" />,
    title: "Fill Out the Health Profile",
    description: "Make a personalized health profile for each of your children based on their unique needs"
  },
  {
    icon: <Scan className="h-6 w-6" />,
    title: "Scan Any Food Product",
    description: "Use your phone's camera to instantly scan food product barcodes while shopping"
  },
  {
    icon: <ShieldCheck className="h-6 w-6" />,
    title: "See Personalized Results",
    description: "Get tailored recommendations on the products you should avoid based on your child's health profile"
  }
];

function Step({ number, icon, title, description, isLast }: {
  number: number;
  icon: React.ReactNode;
  title: string;
  description: string;
  isLast: boolean;
}) {
  return (
    <div className="relative group">
      <div className="bg-white rounded-2xl p-6 md:p-8 shadow-sm hover:shadow-md transition-shadow relative">
        {/* Step Number - Responsive sizing */}
        <div className="absolute -top-3 -left-3 w-6 h-6 md:w-8 md:h-8 bg-emerald-600 text-white rounded-lg flex items-center justify-center font-bold text-sm">
          {number}
        </div>

        {/* Icon with animated background */}
        <div className="mb-6 relative">
          <div className="w-12 h-12 bg-emerald-50 rounded-lg flex items-center justify-center text-emerald-600 group-hover:scale-110 transition-transform">
            {icon}
          </div>
          <div className="absolute inset-0 bg-emerald-100 rounded-lg opacity-0 group-hover:opacity-20 transition-opacity" />
        </div>

        {/* Content - Responsive text sizing */}
        <h3 className="text-lg md:text-xl font-semibold text-gray-900 mb-2">{title}</h3>
        <p className="text-sm md:text-base text-gray-600">{description}</p>

        {/* Connector Arrow - Only visible on larger screens */}
        {!isLast && (
          <div className="hidden lg:block absolute top-1/2 -right-8 transform -translate-y-1/2 text-emerald-300">
            <ArrowRight className="w-4 h-4" />
          </div>
        )}
      </div>

      {/* Mobile Connector - Only visible on smaller screens */}
      {!isLast && (
        <div className="flex justify-center lg:hidden my-4">
          <ArrowRight className="w-4 h-4 text-emerald-300 transform rotate-90" />
        </div>
      )}
    </div>
  );
}