import { AlertTriangle } from 'lucide-react';

const products = [
  {
    name: "PRIME",
    image: "/prime.png",
    reality: "As much sugar as soda"
  },
  {
    name: "Lunchly",
    image: "/lunchly.png",
    reality: "Less healthy than Lunchables"
  },
  {
    name: "Yoplait",
    image: "/yoplait.png",
    reality: "Mostly corn syrup and artificial colors"
  },
  {
    name: "Veggie Straws",
    image: "/veggiestraws.png",
    reality: "Mostly potato starch and oil"
  },
  {
    name: "Fruit Snacks",
    image: "/fruitsnacks.png",
    reality: "High in added sugars"
  },
];

export default function Benefits() {
  return (
    <section className="py-12 sm:py-16 bg-white overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-12">
          <span className="text-gray-900 font-medium text-sm sm:text-base">Popular Foods Exposed</span>
          <h2 className="mt-4 text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900">
          Beware of these <span className="text-emerald-600">"Kid-Friendly"</span> foods on your next shopping trip
          </h2>
        </div>

        {/* Continuous Scrolling Banner */}
        <div className="relative mb-8 sm:mb-12">
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-y-0 left-0 w-16 sm:w-32 bg-gradient-to-r from-white to-transparent z-6" />
            <div className="absolute inset-y-0 right-0 w-16 sm:w-32 bg-gradient-to-l from-white to-transparent z-6" />
          </div>

          <div className="flex gap-4 sm:gap-6 overflow-hidden">
            <div className="flex gap-4 sm:gap-6 animate-scroll">
              {[...products, ...products].map((product, index) => (
                <div 
                  key={index}
                  className="flex-none w-56 sm:w-72 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100 overflow-hidden"
                >
                  <div className="h-32 sm:h-48 overflow-hidden bg-gray-50">
                    <img 
                      src={product.image}
                      alt={product.name}
                      className={`w-full h-full object-contain transform hover:scale-105 transition-transform ${
                        product.name === "Lunchly" ? "scale-150 p-0" : "p-2"
                      }`}
                    />
                  </div>
                  <div className="p-3 sm:p-4">
                    <h3 className="font-semibold text-gray-900 text-sm sm:text-base">{product.name}</h3>
                    <div className="mt-2 space-y-1 sm:space-y-2">
                      <div className="flex items-center gap-1.5 sm:gap-2">
                        <AlertTriangle className="h-3 w-3 sm:h-4 sm:w-4 text-red-500" />
                        <span className="text-xs sm:text-sm text-red-600">{product.reality}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}