import { z } from 'zod';

const productSchema = z.object({
  product: z.object({
    product_name: z.string().nullable().optional(),
    brands: z.string().nullable().optional(),
    ingredients_text: z.string().nullable().optional(),
    serving_size: z.string().nullable().optional(),
    nutriments: z.object({
      'energy-kcal_serving': z.number().nullable().optional(),
      proteins_serving: z.number().nullable().optional(),
      carbohydrates_serving: z.number().nullable().optional(),
      fat_serving: z.number().nullable().optional(),
      'energy-kcal_100g': z.number().nullable().optional(),
      proteins_100g: z.number().nullable().optional(),
      carbohydrates_100g: z.number().nullable().optional(),
      fat_100g: z.number().nullable().optional(),
    }).nullable().optional(),
    allergens_hierarchy: z.array(z.string()).nullable().optional(),
    ingredients_analysis_tags: z.array(z.string()).nullable().optional(),
    nutriscore_grade: z.string().nullable().optional(),
    image_url: z.string().nullable().optional(),
  }),
  status: z.number(),
  status_verbose: z.string().optional(),
});

export type ProductData = z.infer<typeof productSchema>;

export class ProductNotFoundError extends Error {
  constructor(barcode: string) {
    super(`Product not found with barcode: ${barcode}`);
    this.name = 'ProductNotFoundError';
  }
}

export class ProductFetchError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ProductFetchError';
  }
}

const cache = new Map<string, { data: ProductData; timestamp: number }>();
const CACHE_DURATION = 1000 * 60 * 5; // 5 minutes

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

function normalizeBarcode(barcode: string): string[] {
  const cleanBarcode = barcode.trim().replace(/[^0-9]/g, '');
  const formats = new Set<string>();

  // Original barcode
  formats.add(cleanBarcode);

  // Handle UPC-A (12 digits)
  if (cleanBarcode.length === 12) {
    // Add leading zero for EAN-13
    formats.add('0' + cleanBarcode);
    // Try without check digit
    formats.add(cleanBarcode.slice(0, -1));
  }

  // Handle EAN-13 (13 digits)
  if (cleanBarcode.length === 13) {
    // If starts with zero, try without it (UPC-A)
    if (cleanBarcode.startsWith('0')) {
      formats.add(cleanBarcode.slice(1));
    }
    // Try without check digit
    formats.add(cleanBarcode.slice(0, -1));
  }

  // Handle UPC-E (6-8 digits)
  if (cleanBarcode.length >= 6 && cleanBarcode.length <= 8) {
    // Add to formats as is
    formats.add(cleanBarcode);
    // Try with leading zeros to make it UPC-A length
    formats.add(cleanBarcode.padStart(12, '0'));
    // Try with leading zeros to make it EAN-13 length
    formats.add(cleanBarcode.padStart(13, '0'));
  }

  return Array.from(formats);
}

async function fetchWithRetry(
  url: string,
  options: RequestInit = {},
  retries = 3,
  backoff = 1000
): Promise<Response> {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        'User-Agent': 'BarcodeBites/1.0 (https://barcodebites.com)',
        ...options.headers,
      },
    });
    
    if (response.status === 429) { // Too Many Requests
      if (retries > 0) {
        await sleep(backoff);
        return fetchWithRetry(url, options, retries - 1, backoff * 2);
      }
    }
    
    return response;
  } catch (error) {
    if (retries > 0) {
      await sleep(backoff);
      return fetchWithRetry(url, options, retries - 1, backoff * 2);
    }
    throw error;
  }
}

async function tryFetchProduct(barcode: string): Promise<ProductData | null> {
  try {
    const response = await fetchWithRetry(
      `https://world.openfoodfacts.org/api/v0/product/${barcode}.json`
    );

    if (!response.ok) {
      // Try US database if world database fails
      const usResponse = await fetchWithRetry(
        `https://us.openfoodfacts.org/api/v0/product/${barcode}.json`
      );
      
      if (!usResponse.ok) {
        if (response.status === 404 || usResponse.status === 404) {
          return null;
        }
        throw new ProductFetchError(`HTTP error! status: ${response.status}`);
      }
      
      return await usResponse.json();
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching product:', error);
    return null;
  }
}

export async function fetchProduct(barcode: string): Promise<ProductData> {
  try {
    // Check cache first using original barcode
    const cached = cache.get(barcode);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      return cached.data;
    }

    // Try all possible barcode formats
    const formats = normalizeBarcode(barcode);
    
    for (const format of formats) {
      const result = await tryFetchProduct(format);
      if (result) {
        // Cache using original barcode
        cache.set(barcode, {
          data: result,
          timestamp: Date.now(),
        });
        return result;
      }
    }

    throw new ProductNotFoundError(barcode);
  } catch (error) {
    if (error instanceof ProductNotFoundError || error instanceof ProductFetchError) {
      throw error;
    }
    console.error('Unexpected error fetching product:', error);
    throw new ProductFetchError('Failed to fetch product data');
  }
}

// Preload example products into cache
export function preloadExampleProducts() {
  const examples = [
    '044000032029', // Lunchables Turkey & Cheddar
    '810116121557', // PRIME Hydration
    '028400090605', // Veggie Straws
  ];
  
  examples.forEach(barcode => {
    fetchProduct(barcode).catch(() => {
      // Silently fail for preloading
    });
  });
}