import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import Scanner from '../components/Scanner';
import ProductInfo from '../components/ProductInfo';
import { History, Camera } from 'lucide-react';

export default function Dashboard() {
  const { user } = useAuth();
  const [barcode, setBarcode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showScanner, setShowScanner] = useState(false);

  const handleScan = async (result: string) => {
    setIsLoading(true);
    setBarcode(result);
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsLoading(false);
  };

  const handleError = (error: string) => {
    setError(error);
    setShowScanner(false);
  };

  // Get name from user metadata
  const userName = user?.user_metadata?.name || 'User';

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Welcome, {userName}</h1>
              <p className="text-gray-600">Scan products to view their details</p>
            </div>
            <button
              onClick={() => setShowScanner(!showScanner)}
              className="flex items-center gap-2 px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
            >
              {showScanner ? <History className="h-5 w-5" /> : <Camera className="h-5 w-5" />}
              {showScanner ? 'View History' : 'Scan Product'}
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-sm p-6">
          {error && (
            <div className="mb-6 bg-red-50 text-red-600 p-4 rounded-lg">
              {error}
            </div>
          )}

          {showScanner ? (
            <Scanner onResult={handleScan} onError={handleError} />
          ) : (
            <div className="space-y-6">
              {barcode ? (
                <ProductInfo barcode={barcode} isLoading={isLoading} />
              ) : (
                <div className="text-center py-12">
                  <Camera className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No Products Scanned</h3>
                  <p className="text-gray-600">
                    Click the "Scan Product" button to start scanning barcodes
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}