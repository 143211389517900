import { useState, useEffect } from 'react';
import { Plus, Baby, AlertTriangle, Activity, Apple } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { getChildProfiles, type ChildProfile } from '../services/userService';
import { useNavigate } from 'react-router-dom';

export default function ChildrenProfiles() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [children, setChildren] = useState<ChildProfile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchChildren = async () => {
      if (!user?.id) return;
      
      try {
        const profiles = await getChildProfiles(user.id);
        setChildren(profiles);
      } catch (err) {
        setError('Failed to load children profiles');
        console.error('Error fetching children:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChildren();
  }, [user?.id]);

  if (isLoading) {
    return <div className="text-center py-4">Loading profiles...</div>;
  }

  if (error) {
    return <div className="text-red-600 py-4">{error}</div>;
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Children's Profiles</h2>
      
      <div className="space-y-4">
        {children.map((child) => (
          <div key={child.id} className="border border-gray-200 rounded-lg p-4">
            <div className="space-y-4">
              {/* Basic Info */}
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 bg-emerald-100 rounded-full flex items-center justify-center">
                    <Baby className="h-6 w-6 text-emerald-600" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">{child.name}</h3>
                    <div className="mt-1 flex items-center gap-2 text-sm text-gray-500">
                      <span>{child.age} years old</span>
                      <span>•</span>
                      <span className="capitalize">{child.activity_level || 'No activity level set'}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Health Details */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-3">
                {/* Allergens */}
                <div className="flex items-start gap-2">
                  <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Allergen Alerts</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {child.allergen_alerts.length > 0 ? (
                        child.allergen_alerts.map((allergen, index) => (
                          <span 
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-amber-100 text-amber-800"
                          >
                            {allergen}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No allergens listed</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Dietary Restrictions */}
                <div className="flex items-start gap-2">
                  <Apple className="h-5 w-5 text-emerald-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Dietary Restrictions</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {child.dietary_restrictions.length > 0 ? (
                        child.dietary_restrictions.map((restriction, index) => (
                          <span 
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-emerald-100 text-emerald-800"
                          >
                            {restriction}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No restrictions listed</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Health Concerns */}
                <div className="flex items-start gap-2">
                  <Activity className="h-5 w-5 text-purple-500 flex-shrink-0 mt-1" />
                  <div>
                    <h4 className="font-medium text-gray-900">Health Considerations</h4>
                    <div className="mt-1 flex flex-wrap gap-1">
                      {child.health_concerns.length > 0 ? (
                        child.health_concerns.map((concern, index) => (
                          <span 
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800"
                          >
                            {concern}
                          </span>
                        ))
                      ) : (
                        <span className="text-sm text-gray-500">No health concerns listed</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <button
          onClick={() => navigate('/child-health-profile')}
          className="flex items-center justify-center w-full py-2 px-4 border-2 border-dashed border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Child Profile
        </button>
      </div>
    </div>
  );
} 