import { useContext, useEffect } from 'react';
import posthog from 'posthog-js';
import { AuthContext } from '../contexts/AuthContext';

// Initialize PostHog
posthog.init('phc_LqlPvXSewE7ZGYbdlZUs2mglFtQCFydJbRKD6jq1QUA', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  // Disable in development
  loaded: (posthog) => {
    if (import.meta.env.DEV) posthog.opt_out_capturing();
  }
});

export function useAuth() {
  const context = useContext(AuthContext);
  
  useEffect(() => {
    // Identify user when auth state changes
    if (context?.user) {
      posthog.identify(context.user.id, {
        email: context.user.email,
        name: context.user.user_metadata?.name,
        // Add any other user properties you want to track
      });
    } else {
      posthog.reset(); // Reset user identification when logged out
    }
  }, [context?.user]);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  
  return context;
} 