import { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader } from '@zxing/browser';
import { Result } from '@zxing/library';
import { Camera, XCircle } from 'lucide-react';

interface ScannerProps {
  onResult: (result: string) => void;
  onError: (error: string) => void; 
}

export default function Scanner({ onResult, onError }: ScannerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isScanning, setIsScanning] = useState(false);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const readerRef = useRef<BrowserMultiFormatReader | null>(null);

  useEffect(() => {
    readerRef.current = new BrowserMultiFormatReader();

    return () => {
      if (isScanning) {
        stopScanning();
      }
    };
  }, [isScanning]);

  const startScanning = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { 
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        } 
      });
      setHasPermission(true);
      
      if (videoRef.current && readerRef.current) {
        videoRef.current.srcObject = stream;
        setIsScanning(true);

        const scanLoop = async () => {
          if (!isScanning || !readerRef.current || !videoRef.current) return;

          try {
            await readerRef.current.decodeFromVideoElement(videoRef.current, (result: Result | undefined) => {
              if (result) {
                onResult(result.getText());
                stopScanning();
              }
            });
          } catch (error) {
            console.error('Scanning error:', error);
            if (isScanning) {
              requestAnimationFrame(scanLoop);
            }
          }
        };

        scanLoop();
      }
    } catch (error) {
      console.error('Camera access error:', error);
      setHasPermission(false);
      onError('Camera permission denied. Please enable camera access to scan barcodes.');
    }
  };

  const stopScanning = () => {
    setIsScanning(false);

    if (videoRef.current?.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }

    // Clear the reader reference
    readerRef.current = null;
  };

  if (hasPermission === false) {
    return (
      <div className="text-center p-6 bg-red-50 rounded-xl">
        <XCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
        <p className="text-red-600 font-medium mb-2">Camera Access Required</p>
        <p className="text-red-600 text-sm">
          Please enable camera permissions in your browser settings to scan barcodes.
        </p>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className="aspect-video bg-gray-900 rounded-xl overflow-hidden relative">
        <video
          ref={videoRef}
          className="w-full h-full object-cover"
          autoPlay
          playsInline
          muted
        />
        
        {!isScanning && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900/80">
            <button
              onClick={startScanning}
              className="flex items-center gap-2 px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors group"
            >
              <Camera className="h-5 w-5 group-hover:scale-110 transition-transform" />
              Start Scanning
            </button>
          </div>
        )}

        {isScanning && (
          <>
            {/* Scanning overlay */}
            <div className="absolute inset-0 pointer-events-none">
              <div className="absolute inset-12 border-2 border-emerald-400 rounded-lg">
                <div className="absolute inset-0 flex items-center">
                  <div className="h-0.5 w-full bg-emerald-400/50 animate-scan" />
                </div>
                <div className="absolute inset-0 border-2 border-white/20 rounded-lg" />
              </div>
            </div>

            {/* Stop button */}
            <button
              onClick={stopScanning}
              className="absolute top-4 right-4 p-2 bg-white/10 rounded-full hover:bg-white/20 transition-colors"
            >
              <XCircle className="h-6 w-6 text-white" />
            </button>
          </>
        )}
      </div>

      <div className="mt-4 text-center text-sm text-gray-600">
        {isScanning ? (
          <p>Position the barcode within the frame to scan</p>
        ) : (
          <p>Click "Start Scanning" to begin</p>
        )}
      </div>
    </div>
  );
}