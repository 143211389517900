import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import Navbar from '../components/Navbar';

export default function ContactPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <main className="pt-24 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
              Contact Us
            </h1>
            <p className="mt-4 text-xl text-gray-600">
              Have questions about Barcode Bites? We're here to help.
            </p>
          </div>

          <div className="grid lg:grid-cols-2 gap-16">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Get in Touch</h2>
              <p className="mt-4 text-lg text-gray-600">
                Send us a message and we'll get back to you as soon as possible.
              </p>

              <div className="mt-8 space-y-6">
                <ContactInfo 
                  icon={<Mail className="h-6 w-6 text-emerald-600" />}
                  title="Email"
                  detail="hi@barcodebites.com"
                />
                <ContactInfo 
                  icon={<Phone className="h-6 w-6 text-emerald-600" />}
                  title="Phone"
                  detail="+1 (847) 212-9684"
                />
                <ContactInfo 
                  icon={<MapPin className="h-6 w-6 text-emerald-600" />}
                  title="Address"
                  detail="123 Food Street, Nutrition City, FC 12345"
                />
              </div>
            </div>

            <div>
              <form className="bg-white rounded-2xl shadow-sm p-8">
                <div className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    />
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    />
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={4}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500"
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-emerald-600 text-white px-4 py-2 rounded-lg hover:bg-emerald-700 transition-colors"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

function ContactInfo({ icon, title, detail }: {
  icon: React.ReactNode;
  title: string;
  detail: string;
}) {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0">{icon}</div>
      <div className="ml-3">
        <h4 className="text-lg font-medium text-gray-900">{title}</h4>
        <p className="mt-1 text-gray-600">{detail}</p>
      </div>
    </div>
  );
}