import { useState } from 'react';
import Navbar from '../components/Navbar';
import { Camera, Smartphone, X } from 'lucide-react';
import PreviewScanner from '../components/PreviewScanner';
import BarcodeInput from '../components/BarcodeInput';
import { useNavigate } from 'react-router-dom';
import { fetchProduct } from '../services/foodApi';

export default function ScannerPage() {
  const navigate = useNavigate();
  const [showScanner, setShowScanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleScanResult = async (result: string) => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Fetch product data
      const product = await fetchProduct(result);
      
      // Navigate to product details with the scanned barcode
      navigate(`/product/${result}`, { state: { product } });
    } catch (err) {
      setError('Unable to find product information. Please try scanning again or enter the barcode manually.');
    } finally {
      setIsLoading(false);
      setShowScanner(false);
    }
  };

  const handleError = (error: string) => {
    console.error('Scan error:', error);
    setShowScanner(false);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <main className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">
              Food Scanner
            </h1>
            <p className="mt-2 text-gray-600">
              Scan any food product to see detailed ingredient information
            </p>
          </div>

          <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
            {error && (
              <div className="p-8 pb-0">
                <div className="bg-red-50 text-red-600 p-4 rounded-lg">
                  {error}
                </div>
              </div>
            )}

            {isLoading ? (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-emerald-600 mx-auto"></div>
                <p className="mt-4 text-gray-600">Loading product information...</p>
              </div>
            ) : (
              <section className="bg-gradient-to-b from-white to-emerald-50/30">
                <div className="max-w-4xl mx-auto p-8">
                  {showScanner ? (
                    <div className="relative rounded-2xl overflow-hidden shadow-xl">
                      <PreviewScanner onResult={handleScanResult} onError={handleError} />
                      <button
                        onClick={() => setShowScanner(false)}
                        className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-50 transition-colors"
                      >
                        <X className="h-5 w-5 text-gray-600" />
                      </button>
                    </div>
                  ) : (
                    <div className="relative">
                      <button
                        onClick={() => setShowScanner(true)}
                        className="w-full bg-white rounded-2xl shadow-xl overflow-hidden group cursor-pointer relative border-2 border-emerald-100 ring-1 ring-emerald-200/50 shadow-emerald-100/50"
                      >
                        {/* Container with fixed aspect ratio */}
                        <div className="relative w-full aspect-[16/9]">
                          {/* Phone Frame */}
                          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[120px] sm:w-[180px] md:w-[220px] h-[240px] sm:h-[360px] md:h-[440px] transform -rotate-12 transition-transform duration-500 group-hover:-rotate-6">
                            {/* ... Phone frame code from ScannerPreview ... */}
                            <div className="absolute -inset-[3px] bg-gradient-to-br from-gray-400 via-gray-200 to-gray-600 rounded-[2.2rem] sm:rounded-[2.7rem] md:rounded-[3.2rem] shadow-lg">
                              <div className="absolute inset-0 bg-gradient-to-tr from-white/60 via-transparent to-white/20 rounded-[2.2rem] sm:rounded-[2.7rem] md:rounded-[3.2rem]" />
                            </div>
                            
                            <div className="absolute inset-0 bg-[#1A1A1C] rounded-[2rem] sm:rounded-[2.5rem] md:rounded-[3rem] overflow-hidden">
                              <div className="absolute inset-0 bg-gradient-to-br from-gray-900/80 to-black/90" />
                              <div className="absolute inset-0 bg-gradient-to-b from-white/10 via-transparent to-white/5" />
                              
                              <div className="absolute inset-1 bg-emerald-50 rounded-[1.75rem] sm:rounded-[2.25rem] md:rounded-[2.75rem] overflow-hidden shadow-inner">
                                <div className="absolute inset-0 flex flex-col">
                                  <div className="p-1.5 sm:p-3 text-center bg-emerald-600 text-white">
                                    <div className="flex items-center justify-center gap-1.5 sm:gap-2">
                                      <Camera className="h-3 sm:h-5 w-3 sm:w-5" />
                                      <span className="text-[10px] sm:text-sm font-medium">Scan Barcode</span>
                                    </div>
                                  </div>
                                  
                                  <div className="flex-1 relative bg-black/90">
                                    <div className="absolute inset-3 sm:inset-6 border-2 border-emerald-400 rounded-lg">
                                      <div className="absolute -top-1 -left-1 w-3 sm:w-4 h-3 sm:h-4 border-t-2 border-l-2 border-emerald-400" />
                                      <div className="absolute -top-1 -right-1 w-3 sm:w-4 h-3 sm:h-4 border-t-2 border-r-2 border-emerald-400" />
                                      <div className="absolute -bottom-1 -left-1 w-3 sm:w-4 h-3 sm:h-4 border-b-2 border-l-2 border-emerald-400" />
                                      <div className="absolute -bottom-1 -right-1 w-3 sm:w-4 h-3 sm:h-4 border-b-2 border-r-2 border-emerald-400" />
                                      
                                      <div className="absolute inset-0 overflow-hidden">
                                        <div className="absolute inset-x-0 top-0 h-full animate-scan">
                                          <div className="h-0.5 w-full bg-emerald-400" />
                                        </div>
                                      </div>

                                      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-20 sm:w-32 text-center">
                                        <div className="bg-emerald-400/20 backdrop-blur-sm px-2 py-1.5 rounded-lg border border-emerald-400/50">
                                          <p className="text-[8px] sm:text-xs text-white font-medium">
                                            Click to Start Scanning
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Primary CTA */}
                          <div className="absolute left-2 sm:left-6 bottom-2 sm:bottom-6">
                            <div className="bg-emerald-600 text-white px-2 sm:px-4 py-1 sm:py-2 rounded-full shadow-lg flex items-center gap-1.5 animate-pulse group-hover:scale-105 transition-transform">
                              <Smartphone className="h-3 sm:h-5 w-3 sm:w-5" />
                              <span className="text-[10px] sm:text-sm font-medium whitespace-nowrap">Scan Here</span>
                            </div>
                          </div>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </section>
            )}
          </div>

          {/* Divider */}
          <div className="relative my-12">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>
            <div className="relative flex justify-center">
              <span className="bg-gray-50 px-4 text-sm text-gray-500">or enter manually</span>
            </div>
          </div>

          {/* Manual Input Component */}
          <BarcodeInput />
        </div>
      </main>
    </div>
  );
} 