import { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { User, Users, Settings as SettingsIcon } from 'lucide-react';
import Navbar from '../components/Navbar';
import ProfileSettings from '../components/ProfileSettings';
import ChildrenProfiles from '../components/ChildrenProfiles';
import AdvancedSettings from '../components/AdvancedSettings';

type TabType = 'profile' | 'children' | 'advanced';

export default function Settings() {
  useAuth();
  const [activeTab, setActiveTab] = useState<TabType>('profile');

  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return <ProfileSettings />;
      case 'children':
        return <ChildrenProfiles />;
      case 'advanced':
        return <AdvancedSettings />;
      default:
        return <ProfileSettings />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <main className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="space-y-6">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Account Settings</h1>
              <p className="mt-1 text-sm text-gray-500">
                Manage your account settings and preferences.
              </p>
            </div>

            <div className="grid grid-cols-12 gap-6">
              {/* Sidebar */}
              <div className="col-span-12 md:col-span-3">
                <nav className="space-y-2">
                  <button
                    onClick={() => setActiveTab('profile')}
                    className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-all duration-200 ${
                      activeTab === 'profile'
                        ? 'bg-emerald-50 text-emerald-700 shadow-sm ring-1 ring-emerald-100'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <User className={`h-5 w-5 ${
                      activeTab === 'profile' ? 'text-emerald-500' : 'text-gray-400'
                    }`} />
                    <span className="text-sm font-medium">Profile Settings</span>
                  </button>
                  <button
                    onClick={() => setActiveTab('children')}
                    className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-all duration-200 ${
                      activeTab === 'children'
                        ? 'bg-emerald-50 text-emerald-700 shadow-sm ring-1 ring-emerald-100'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <Users className={`h-5 w-5 ${
                      activeTab === 'children' ? 'text-emerald-500' : 'text-gray-400'
                    }`} />
                    <span className="text-sm font-medium">Children's Profiles</span>
                  </button>
                  <button
                    onClick={() => setActiveTab('advanced')}
                    className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-all duration-200 ${
                      activeTab === 'advanced'
                        ? 'bg-emerald-50 text-emerald-700 shadow-sm ring-1 ring-emerald-100'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                    }`}
                  >
                    <SettingsIcon className={`h-5 w-5 ${
                      activeTab === 'advanced' ? 'text-emerald-500' : 'text-gray-400'
                    }`} />
                    <span className="text-sm font-medium">Advanced Settings</span>
                  </button>
                </nav>
              </div>

              {/* Main content */}
              <div className="col-span-12 md:col-span-9">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
} 