import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';

export function PostHogPageview() {
  const location = useLocation();

  useEffect(() => {
    // Track pageview when location changes
    posthog.capture('$pageview', {
      current_url: window.location.href,
      pathname: location.pathname,
    });
  }, [location]);

  return null;
} 