import { useState } from 'react';
import { Baby, Activity, Apple, Brain, AlertTriangle } from 'lucide-react';
import Navbar from '../components/Navbar';
import { useAuth } from '../hooks/useAuth';
import { createChildProfile } from '../services/userService';
import ChildProfileSummary from '../components/ChildProfileSummary';

interface ChildInfo {
  parentName: string;
  name: string;
  age: string;
  activityLevel: string;
  dietaryRestrictions: string[];
  healthConcerns: string[];
}

const activityLevels = [
  { id: 'low', label: 'Low Activity', description: 'Mostly sedentary, occasional light activity' },
  { id: 'moderate', label: 'Moderate Activity', description: 'Regular play and light exercise' },
  { id: 'high', label: 'High Activity', description: 'Very active, regular sports or intense play' }
];

const dietaryOptions = [
  { id: 'vegetarian', label: 'Vegetarian' },
  { id: 'vegan', label: 'Vegan' },
  { id: 'gluten-free', label: 'Gluten-Free' },
  { id: 'dairy-free', label: 'Dairy-Free' },
  { id: 'kosher', label: 'Kosher' },
  { id: 'halal', label: 'Halal' }
];

const healthConcernOptions = [
  { id: 'adhd', label: 'ADHD' },
  { id: 'autism', label: 'Autism' },
  { id: 'diabetes', label: 'Diabetes' },
  { id: 'obesity', label: 'Weight Management' },
  { id: 'heart', label: 'Heart Health' },
  { id: 'behavioral', label: 'Behavioral Sensitivity to Food' }
];

export default function ChildHealthProfile() {
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [childInfo, setChildInfo] = useState<ChildInfo>({
    parentName: '',
    name: '',
    age: '',
    activityLevel: '',
    dietaryRestrictions: [],
    healthConcerns: []
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const handleNext = async () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    } else {
      // Submit form
      if (!user?.id) {
        setError('You must be logged in to create a child profile');
        return;
      }

      if (!childInfo.parentName || !childInfo.name || !childInfo.age || !childInfo.activityLevel) {
        setError('Please fill out all required fields');
        return;
      }

      setIsSubmitting(true);
      setError(null);

      try {
        await createChildProfile(user.id, {
          parent_name: childInfo.parentName,
          name: childInfo.name,
          age: parseInt(childInfo.age),
          activity_level: childInfo.activityLevel as 'low' | 'moderate' | 'high',
          dietary_restrictions: childInfo.dietaryRestrictions,
          health_concerns: childInfo.healthConcerns,
          allergen_alerts: [] 
        });

        setIsProfileComplete(true);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to create child profile');
        console.error('Error creating child profile:', err);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateChildInfo = (field: keyof ChildInfo, value: string | string[]) => {
    setChildInfo(prev => ({ ...prev, [field]: value }));
  };

  const toggleArrayItem = (field: 'dietaryRestrictions' | 'healthConcerns', item: string) => {
    setChildInfo(prev => ({
      ...prev,
      [field]: prev[field].includes(item)
        ? prev[field].filter(i => i !== item)
        : [...prev[field], item]
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-2xl shadow-sm p-8">
            {isProfileComplete ? (
              <ChildProfileSummary childInfo={childInfo} />
            ) : (
              <>
                {/* Header */}
                <div className="text-center mb-8">
                  <h1 className="text-3xl font-bold text-gray-900">
                    Child Health Profile
                  </h1>
                  <p className="mt-2 text-gray-600">
                    Help us understand your child's needs for better food recommendations
                  </p>
                </div>

                {/* Progress Steps */}
                <div className="mb-8">
                  <div className="flex justify-between items-center">
                    {[1, 2, 3, 4].map((step) => (
                      <div key={step} className="flex items-center">
                        <div className={`
                          w-8 h-8 rounded-full flex items-center justify-center
                          ${currentStep >= step ? 'bg-emerald-600 text-white' : 'bg-gray-200 text-gray-600'}
                        `}>
                          {step}
                        </div>
                        {step < 4 && (
                          <div className={`
                            w-full h-1 mx-2
                            ${currentStep > step ? 'bg-emerald-600' : 'bg-gray-200'}
                          `} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Step Content */}
                <div className="mb-8">
                  {currentStep === 1 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Baby className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Basic Information</h2>
                          <p className="text-gray-600">Let's start with some basic details</p>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Parent's Name
                        </label>
                        <input
                          type="text"
                          value={childInfo.parentName}
                          onChange={(e) => updateChildInfo('parentName', e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                          placeholder="Enter your name"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Child's Name
                        </label>
                        <input
                          type="text"
                          value={childInfo.name}
                          onChange={(e) => updateChildInfo('name', e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                          placeholder="Enter your child's name"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Age
                        </label>
                        <input
                          type="number"
                          value={childInfo.age}
                          onChange={(e) => updateChildInfo('age', e.target.value)}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                          placeholder="Enter age in years"
                          min="1"
                          max="17"
                        />
                      </div>
                    </div>
                  )}

                  {currentStep === 2 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Activity className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Activity Level</h2>
                          <p className="text-gray-600">Help us understand your child's energy needs</p>
                        </div>
                      </div>

                      <div className="space-y-4">
                        {activityLevels.map((level) => (
                          <div
                            key={level.id}
                            className={`
                              p-4 border rounded-xl cursor-pointer transition-all
                              ${childInfo.activityLevel === level.id
                                ? 'border-emerald-500 bg-emerald-50'
                                : 'border-gray-200 hover:border-emerald-200'
                              }
                            `}
                            onClick={() => updateChildInfo('activityLevel', level.id)}
                          >
                            <div className="flex items-center gap-3">
                              <div className={`
                                w-5 h-5 rounded-full border-2 flex items-center justify-center
                                ${childInfo.activityLevel === level.id
                                  ? 'border-emerald-500'
                                  : 'border-gray-300'
                                }
                              `}>
                                {childInfo.activityLevel === level.id && (
                                  <div className="w-3 h-3 rounded-full bg-emerald-500" />
                                )}
                              </div>
                              <div>
                                <h3 className="font-medium text-gray-900">{level.label}</h3>
                                <p className="text-sm text-gray-600">{level.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {currentStep === 3 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Apple className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Dietary Restrictions</h2>
                          <p className="text-gray-600">Select any dietary restrictions that apply</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        {dietaryOptions.map((option) => (
                          <button
                            key={option.id}
                            onClick={() => toggleArrayItem('dietaryRestrictions', option.id)}
                            className={`
                              p-4 border rounded-xl text-left transition-all
                              ${childInfo.dietaryRestrictions.includes(option.id)
                                ? 'border-emerald-500 bg-emerald-50'
                                : 'border-gray-200 hover:border-emerald-200'
                              }
                            `}
                          >
                            <div className="flex items-center gap-3">
                              <div className={`
                                w-5 h-5 rounded flex items-center justify-center border-2
                                ${childInfo.dietaryRestrictions.includes(option.id)
                                  ? 'border-emerald-500 bg-emerald-500'
                                  : 'border-gray-300'
                                }
                              `}>
                                {childInfo.dietaryRestrictions.includes(option.id) && (
                                  <svg className="w-3 h-3 text-white" viewBox="0 0 24 24">
                                    <path
                                      fill="currentColor"
                                      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="font-medium text-gray-900">{option.label}</span>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  {currentStep === 4 && (
                    <div className="space-y-6">
                      <div className="flex items-center gap-4 mb-6">
                        <div className="w-12 h-12 bg-emerald-100 rounded-xl flex items-center justify-center">
                          <Brain className="w-6 h-6 text-emerald-600" />
                        </div>
                        <div>
                          <h2 className="text-xl font-semibold text-gray-900">Health Considerations</h2>
                          <p className="text-gray-600">Select any health concerns we should consider</p>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        {healthConcernOptions.map((option) => (
                          <button
                            key={option.id}
                            onClick={() => toggleArrayItem('healthConcerns', option.id)}
                            className={`
                              p-4 border rounded-xl text-left transition-all
                              ${childInfo.healthConcerns.includes(option.id)
                                ? 'border-emerald-500 bg-emerald-50'
                                : 'border-gray-200 hover:border-emerald-200'
                              }
                            `}
                          >
                            <div className="flex items-center gap-3">
                              <div className={`
                                w-5 h-5 rounded flex items-center justify-center border-2
                                ${childInfo.healthConcerns.includes(option.id)
                                  ? 'border-emerald-500 bg-emerald-500'
                                  : 'border-gray-300'
                                }
                              `}>
                                {childInfo.healthConcerns.includes(option.id) && (
                                  <svg className="w-3 h-3 text-white" viewBox="0 0 24 24">
                                    <path
                                      fill="currentColor"
                                      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                                    />
                                  </svg>
                                )}
                              </div>
                              <span className="font-medium text-gray-900">{option.label}</span>
                            </div>
                          </button>
                        ))}
                      </div>

                      <div className="mt-6 p-4 bg-amber-50 rounded-xl">
                        <div className="flex items-start gap-3">
                          <AlertTriangle className="w-5 h-5 text-amber-500 mt-0.5" />
                          <div>
                            <h4 className="font-medium text-amber-900">Important Note</h4>
                            <p className="text-sm text-amber-700 mt-1">
                              Our recommendations are general guidelines. Always consult with healthcare
                              professionals for specific dietary advice related to medical conditions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Navigation Buttons */}
                <div className="flex justify-between">
                  <button
                    onClick={handleBack}
                    className={`
                      px-6 py-2 rounded-lg border border-gray-300 text-gray-700
                      hover:bg-gray-50 transition-colors
                      ${currentStep === 1 ? 'invisible' : ''}
                    `}
                    disabled={isSubmitting}
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNext}
                    disabled={isSubmitting}
                    className="px-6 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting 
                      ? 'Saving...' 
                      : currentStep === 4 
                        ? 'Complete Profile' 
                        : 'Next'
                    }
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </main>

      {/* Add error message above navigation buttons */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-md">
          {error}
        </div>
      )}
    </div>
  );
}