import { Scan, Apple } from 'lucide-react';

export default function Logo() {
  return (
    <div className="flex items-center gap-1">
      <div className="relative">
        <Scan className="h-8 w-8 text-emerald-600" />
        <Apple className="h-4 w-4 text-emerald-500 absolute -bottom-1 -right-1" />
      </div>
      <span className="ml-2 text-xl font-bold text-gray-900">
        Barcode<span className="text-emerald-600">Bites</span>
      </span>
    </div>
  );
}