import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Navbar from '../components/Navbar';

const faqs = [
  {
    question: "How does the barcode scanning work?",
    answer: "Our app uses your device's camera to scan product barcodes. Once scanned, we match the barcode against our extensive database of food products to provide you with detailed ingredient information instantly."
  },
  {
    question: "Can I scan any product?",
    answer: "Barcode Bites works with most packaged food products available in major retailers. Our database is continuously updated with new products, and you can even contribute by submitting new items."
  },
  {
    question: "What makes this app different from others?",
    answer: "Unlike other apps, Barcode Bites provides comprehensive ingredient analysis, allergen alerts, and environmental impact data all in one place. We also offer personalized recommendations based on your dietary preferences."
  },
  {
    question: "Is it free to use?",
    answer: "We offer a free basic version that includes barcode scanning and basic ingredient information. Premium features like detailed nutritional analysis and personalized recommendations are available with a subscription."
  },
  {
    question: "How accurate is the information?",
    answer: "We source our data from official product databases and manufacturer information, ensuring high accuracy. Our system is regularly updated to maintain data quality."
  }
];

export default function FAQPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <main className="pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
              Frequently Asked Questions
            </h1>
            <p className="mt-4 text-xl text-gray-600">
              Everything you need to know about FoodScan
            </p>
          </div>

          <div className="bg-white rounded-2xl shadow-sm p-8">
            <div className="space-y-6">
              {faqs.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>

          <div className="mt-12 text-center">
            <p className="text-gray-600">
              Still have questions?{' '}
              <a href="#contact" className="text-emerald-600 hover:text-emerald-700 font-medium">
                Contact our support team
              </a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

function FAQItem({ question, answer }: { question: string; answer: string }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="border-b border-gray-200 pb-4">
      <button
        className="w-full text-left py-4 flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900">{question}</span>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="mt-2 pr-12">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
}