import { useEffect, useState } from 'react';
import { X, AlertTriangle, ShieldCheck, Loader2, Package, Sparkles, CheckCircle2, XCircle, Shield } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { getProduct, recordScan } from '../services/productService';
import { getAIRecommendationStream } from '../services/aiService';
import type { Product } from '../types/supabase';
import type { AIRecommendation } from '../types/ai';

interface ProductPopupProps {
  isOpen: boolean;
  onClose: () => void;
  barcode: string;
}

function formatMarkdownBold(text: string): React.ReactNode {
  const parts = text.split(/(\*\*.*?\*\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return part;
  });
}

export default function ProductPopup({ isOpen, onClose, barcode }: ProductPopupProps) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [aiRecommendation, setAiRecommendation] = useState<AIRecommendation | null>(null);
  const [aiError, setAiError] = useState<string | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [streamingResponse, setStreamingResponse] = useState<Partial<AIRecommendation> | null>(null);

  useEffect(() => {
    if (isOpen && barcode) {
      setIsLoading(true);
      setError(null);
      setAiError(null);
      setProduct(null);
      setStreamingResponse(null);
      
      // First get the product data
      getProduct(barcode)
        .then(async (productData) => {
          setProduct(productData);
          setIsLoading(false);
          
          // Start AI analysis with streaming
          setIsAnalyzing(true);
          try {
            const stream = await getAIRecommendationStream(productData, user?.id);
            for await (const chunk of stream) {
              setStreamingResponse(current => ({
                ...current,
                ...chunk
              }));
            }
            setAiRecommendation(streamingResponse as AIRecommendation);
          } catch (err) {
            console.error('AI recommendation error:', err);
            setAiError('Unable to analyze product. Showing basic information only.');
          } finally {
            setIsAnalyzing(false);
          }

          // Record scan in background
          if (user) {
            recordScan(user.id, barcode).catch(err => {
              console.error('Error recording scan:', err);
            });
          }
        })
        .catch(err => {
          console.error('Product fetch error:', err);
          if (err.name === 'ProductNotFoundError') {
            setError('Product not found. Please try a different barcode.');
          } else {
            setError('Unable to fetch product information');
          }
          setIsLoading(false);
        });
    }
  }, [isOpen, barcode, user]);

  if (!isOpen) return null;

  const formatAllergen = (allergen: string) => {
    return allergen.replace('en:', '').split('_').join(' ').toLowerCase();
  };

  // Helper function to safely render AI recommendation sections
  const renderAIRecommendation = () => {
    if (isAnalyzing || streamingResponse) {
      return (
        <div className="space-y-6">
          {/* Analysis Section */}
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex items-center gap-2 mb-3">
              <Sparkles className="h-5 w-5 text-emerald-600" />
              <h3 className="font-medium text-gray-900">Analysis</h3>
              {isAnalyzing && (
                <Loader2 className="h-4 w-4 animate-spin text-gray-500 ml-2" />
              )}
            </div>
            <p className="text-gray-700">
              {streamingResponse?.reasoning ? 
                formatMarkdownBold(streamingResponse.reasoning) 
                : 'Analyzing product...'}
            </p>
          </div>

          {/* Healthy Ingredients - show if available */}
          {streamingResponse?.healthyIngredients && streamingResponse.healthyIngredients.length > 0 && (
            <div className="bg-emerald-50 rounded-xl p-6">
              <h3 className="font-medium mb-4 text-emerald-900">
                Beneficial Ingredients
              </h3>
              <ul className="space-y-3">
                {streamingResponse.healthyIngredients.map((ingredient, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <CheckCircle2 className="h-5 w-5 text-emerald-600 mt-0.5 flex-shrink-0" />
                    <span className="text-emerald-800">
                      {formatMarkdownBold(ingredient)}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Concerning Ingredients - show if available */}
          {streamingResponse?.concerningIngredients && streamingResponse.concerningIngredients.length > 0 && (
            <div className="bg-red-50 rounded-xl p-6">
              <h3 className="font-medium mb-4 text-red-900">
                Ingredients of Concern
              </h3>
              <ul className="space-y-3">
                {streamingResponse.concerningIngredients.map((ingredient, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <XCircle className="h-5 w-5 text-red-600 mt-0.5 flex-shrink-0" />
                    <span className="text-red-800">
                      {formatMarkdownBold(ingredient)}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    }

    if (!aiRecommendation) {
      if (aiError) {
        return (
          <div className="bg-amber-50 rounded-xl p-6">
            <div className="flex items-center gap-2 mb-3">
              <AlertTriangle className="h-5 w-5 text-amber-500" />
              <h3 className="font-medium text-amber-900">Analysis Unavailable</h3>
            </div>
            <p className="text-amber-700">{aiError}</p>
          </div>
        );
      }
      return null;
    }

    return (
      <div className="space-y-6">
        {/* Reasoning Section */}
        <div className="bg-gray-50 rounded-xl p-6">
          <div className="flex items-center gap-2 mb-3">
            <Sparkles className="h-5 w-5 text-emerald-600" />
            <h3 className="font-medium text-gray-900">Analysis</h3>
          </div>
          <p className="text-gray-700">{aiRecommendation.reasoning}</p>
        </div>

        {/* Show Healthy Ingredients only if suitable */}
        {aiRecommendation.suitable && 
         aiRecommendation.healthyIngredients?.length > 0 && (
          <div className="bg-emerald-50 rounded-xl p-6">
            <h3 className="font-medium mb-4 text-emerald-900">
              Beneficial Ingredients
            </h3>
            <ul className="space-y-3">
              {aiRecommendation.healthyIngredients.map((ingredient, index) => (
                <li key={index} className="flex items-start gap-2">
                  <CheckCircle2 className="h-5 w-5 text-emerald-600 mt-0.5 flex-shrink-0" />
                  <span className="text-emerald-800">
                    {formatMarkdownBold(ingredient)}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Show Concerning Ingredients only if not suitable */}
        {!aiRecommendation.suitable &&
         aiRecommendation.concerningIngredients?.length > 0 && (
          <div className="bg-red-50 rounded-xl p-6">
            <h3 className="font-medium mb-4 text-red-900">
              Ingredients of Concern
            </h3>
            <ul className="space-y-3">
              {aiRecommendation.concerningIngredients.map((ingredient, index) => (
                <li key={index} className="flex items-start gap-2">
                  <XCircle className="h-5 w-5 text-red-600 mt-0.5 flex-shrink-0" />
                  <span className="text-red-800">
                    {formatMarkdownBold(ingredient)}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end sm:items-center justify-center p-0 sm:p-4">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/40 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div className="relative w-full h-[90vh] sm:h-auto sm:max-h-[90vh] sm:max-w-2xl bg-white rounded-t-2xl sm:rounded-2xl shadow-2xl overflow-hidden">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 p-2 rounded-full bg-white/90 shadow-lg hover:bg-white transition-colors z-10"
        >
          <X className="h-5 w-5 text-gray-600" />
        </button>

        {isLoading ? (
          <div className="h-[80vh] sm:h-[32rem] flex items-center justify-center">
            <div className="text-center">
              <div className="animate-pulse space-y-4 mb-8">
                <div className="flex items-center justify-center gap-6">
                  {/* Skeleton for product image */}
                  <div className="w-32 h-32 bg-gray-200 rounded-xl"></div>
                  <div className="space-y-3">
                    {/* Skeleton for product name */}
                    <div className="h-7 bg-gray-200 rounded w-48"></div>
                    {/* Skeleton for brand */}
                    <div className="h-4 bg-gray-200 rounded w-32"></div>
                  </div>
                </div>
              </div>
              <Loader2 className="h-8 w-8 text-emerald-600 animate-spin mx-auto" />
              <p className="mt-4 text-gray-600">Analyzing product...</p>
            </div>
          </div>
        ) : error ? (
          <div className="h-[80vh] sm:h-[32rem] flex items-center justify-center p-8">
            <div className="text-center">
              <AlertTriangle className="h-12 w-12 text-amber-500 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">{error}</h3>
              <p className="text-gray-600">Please try scanning again or enter the barcode manually.</p>
            </div>
          </div>
        ) : product ? (
          <>
            {/* Product Header */}
            <div className="relative bg-gradient-to-b from-gray-50 to-white">
              <div className="px-4 sm:px-6 py-6 sm:py-8">
                <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6">
                  {/* Product Image */}
                  <div className="w-32 sm:w-40 h-32 sm:h-40 flex-shrink-0 bg-white rounded-xl shadow-sm p-4 flex items-center justify-center">
                    {product.image_url ? (
                      <img
                        src={product.image_url}
                        alt={product.name || 'Product image'}
                        className="max-w-full max-h-full object-contain"
                      />
                    ) : (
                      <Package className="h-16 w-16 text-gray-400" />
                    )}
                  </div>

                  {/* Product Info */}
                  <div className="flex-1 text-center sm:text-left">
                    <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">
                      {product.name || 'Unknown Product'}
                    </h2>
                    <p className="text-gray-600 mb-4">{product.brand || 'Unknown Brand'}</p>

                    {streamingResponse?.suitable !== undefined && (
                      <div className={`inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-sm sm:text-base ${
                        streamingResponse.suitable
                          ? 'bg-emerald-50 text-emerald-700'
                          : 'bg-red-50 text-red-700'
                      }`}>
                        {streamingResponse.suitable ? (
                          <Shield className="h-5 w-5" />
                        ) : (
                          <AlertTriangle className="h-5 w-5" />
                        )}
                        <span className="font-medium">
                          {streamingResponse.suitable ? 'Safe for Your Family' : 'Not Recommended'}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="px-4 sm:px-6 py-4 sm:py-6 max-h-[calc(60vh-8rem)] sm:max-h-[calc(100vh-24rem)] overflow-y-auto">
              {/* AI Analysis */}
              {renderAIRecommendation()}

              {/* Allergens Section - only show if there are actual allergens */}
              {product.allergens && product.allergens.length > 0 && (
                <div className="p-3 sm:p-4 rounded-xl bg-amber-50 border border-amber-100 mt-6">
                  <div className="flex items-start gap-3">
                    <AlertTriangle className="h-5 w-5 text-amber-500 mt-0.5" />
                    <div>
                      <h4 className="font-medium text-amber-900">Allergen Alert</h4>
                      <p className="text-sm mt-1 text-amber-700">
                        Contains: {product.allergens.map(formatAllergen).join(', ')}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Ingredients - only show if ingredients exist and aren't placeholder text */}
              {product.ingredients && 
               !product.ingredients.toLowerCase().includes('ingredient') && // Avoid placeholder text
               !product.ingredients.toLowerCase().includes('not provided') && 
               product.ingredients.trim() !== '' && (
                <div className="mt-6">
                  <h4 className="font-medium text-gray-900 mb-3 flex items-center gap-2">
                    <ShieldCheck className="h-5 w-5 text-emerald-500" />
                    Ingredients
                  </h4>
                  <div className="bg-gray-50 rounded-xl p-3 sm:p-4">
                    <p className="text-gray-600 text-sm sm:text-base">{product.ingredients}</p>
                  </div>
                </div>
              )}
            </div>

            {/* Footer */}
            <div className="px-4 sm:px-6 py-3 sm:py-4 bg-gray-50 border-t border-gray-100">
              <div className="flex items-center justify-between text-xs sm:text-sm">
                <p className="text-gray-500">Barcode: {barcode}</p>
                <p className="text-gray-400">Data from Open Food Facts</p>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}