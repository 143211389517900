import { AlertTriangle, Check, Info } from 'lucide-react';

interface ProductInfoProps {
  barcode: string;
  isLoading: boolean;
}

interface ProductData {
  name: string;
  brand: string;
  ingredients: string[];
  allergens: string[];
  nutritionalInfo: {
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
  };
}

// Simulated product data - in a real app, this would come from an API
const mockProductData: ProductData = {
  name: "Organic Granola",
  brand: "Nature's Best",
  ingredients: [
    "Rolled oats",
    "Honey",
    "Almonds",
    "Coconut oil",
    "Vanilla extract"
  ],
  allergens: ["Tree nuts"],
  nutritionalInfo: {
    calories: 120,
    protein: 3,
    carbs: 22,
    fat: 6
  }
};

export default function ProductInfo({ barcode, isLoading }: ProductInfoProps) {
  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-2xl font-bold text-gray-900">{mockProductData.name}</h3>
        <p className="text-gray-600">{mockProductData.brand}</p>
      </div>

      {mockProductData.allergens.length > 0 && (
        <div className="bg-amber-50 border border-amber-200 rounded-lg p-4">
          <div className="flex items-center gap-2 text-amber-600 mb-2">
            <AlertTriangle className="h-5 w-5" />
            <h4 className="font-medium">Allergen Warning</h4>
          </div>
          <p className="text-amber-700">
            Contains: {mockProductData.allergens.join(', ')}
          </p>
        </div>
      )}

      <div>
        <h4 className="font-medium text-gray-900 mb-2 flex items-center gap-2">
          <Info className="h-5 w-5 text-gray-600" />
          Ingredients
        </h4>
        <ul className="list-disc list-inside space-y-1 text-gray-600">
          {mockProductData.ingredients.map((ingredient, index) => (
            <li key={index}>{ingredient}</li>
          ))}
        </ul>
      </div>

      <div>
        <h4 className="font-medium text-gray-900 mb-2 flex items-center gap-2">
          <Check className="h-5 w-5 text-emerald-600" />
          Nutrition Facts
        </h4>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-gray-50 p-3 rounded-lg">
            <div className="text-sm text-gray-600">Calories</div>
            <div className="text-lg font-medium">{mockProductData.nutritionalInfo.calories}</div>
          </div>
          <div className="bg-gray-50 p-3 rounded-lg">
            <div className="text-sm text-gray-600">Protein</div>
            <div className="text-lg font-medium">{mockProductData.nutritionalInfo.protein}g</div>
          </div>
          <div className="bg-gray-50 p-3 rounded-lg">
            <div className="text-sm text-gray-600">Carbs</div>
            <div className="text-lg font-medium">{mockProductData.nutritionalInfo.carbs}g</div>
          </div>
          <div className="bg-gray-50 p-3 rounded-lg">
            <div className="text-sm text-gray-600">Fat</div>
            <div className="text-lg font-medium">{mockProductData.nutritionalInfo.fat}g</div>
          </div>
        </div>
      </div>

      <div className="text-sm text-gray-500">
        Barcode: {barcode}
      </div>
    </div>
  );
}