import { useState } from 'react';
import { Check } from 'lucide-react';
import Navbar from '../components/Navbar';
import Testimonials from '../components/Testimonials';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { supabase } from '../lib/supabase';

const tiers = [
  {
    name: 'One Child Plan',
    price: '26',
    priceId: 'price_1QTVC6GUPHP3wnnXhW5S6Fo9',
    description: 'For occasional food scanning needs',
    features: [
      '100 scans per month',
      'Ingredient analysis',
      'Allergen detection',
      'Nutritional information',
      'Web & mobile access',
      'Email support'
    ],
    cta: 'Start Free Trial',
    highlighted: false
  },
  {
    name: 'Family Plan',
    price: '29',
    priceId: 'price_1QTVCJGUPHP3wnnXe6A3yIPj',
    description: 'Perfect for health-conscious individuals',
    features: [
      'Unlimited scans',
      'Ingredient analysis',
      'Allergen detection',
      'Nutritional information',
      'Web & mobile access',
      'Priority email support',
      'Advanced analytics'
    ],
    cta: 'Start Free Trial',
    highlighted: true
  },
  {
    name: 'Founder\'s Circle',
    price: '150',
    priceId: 'price_1QTVCoGUPHP3wnnXelYc6nSa',
    description: 'Premium experience with personal guidance',
    features: [
      'Unlimited scans',
      'All Pro features',
      'Personal recommendations',
      'Monthly consultation calls',
      'Direct founder access',
      'Early access to features',
      'Custom analysis reports'
    ],
    cta: 'Start Free Trial',
    highlighted: false
  }
];

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export default function PricingPage() {
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSubscribe = async (priceId: string) => {
    try {
      if (!user) {
        navigate('/login');
        return;
      }

      setIsLoading(priceId);
      
      // Get current session and access token
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) {
        console.error('No valid session found');
        navigate('/login');
        return;
      }

      // Safer JWT decoding
      try {
        const parts = session.access_token.split('.');
        if (parts.length === 3) {
          const [headerB64, payloadB64] = parts;
          
          // Properly pad the base64 strings
          const padBase64 = (str: string) => {
            const padding = '='.repeat((4 - str.length % 4) % 4);
            return str + padding;
          };

          // Decode header and payload
          const header = JSON.parse(atob(padBase64(headerB64.replace(/-/g, '+').replace(/_/g, '/'))));
          const payload = JSON.parse(atob(padBase64(payloadB64.replace(/-/g, '+').replace(/_/g, '/'))));

          console.log('JWT Details:', {
            header,
            payload,
            expiresAt: new Date(payload.exp * 1000).toISOString(),
            isExpired: Date.now() > payload.exp * 1000
          });
        }
      } catch (jwtError) {
        console.warn('Failed to decode JWT token:', jwtError);
        // Continue with the request even if token decoding fails
      }

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({
          priceId,
          successUrl: `${window.location.origin}/?success=true`,
          cancelUrl: `${window.location.origin}/pricing`,
        })
      });

      // Log the full response for debugging
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Full response:', {
          status: response.status,
          headers: Object.fromEntries(response.headers.entries()),
          body: errorText
        });
        
        if (response.status === 401) {
          await delay(1000);
          const { data: { session: newSession }, error: refreshError } = await supabase.auth.refreshSession();
          if (refreshError || !newSession) {
            console.error('Session refresh failed:', refreshError);
            await supabase.auth.signOut();
            navigate('/login');
            return;
          }
          return handleSubscribe(priceId);
        }
        
        throw new Error(`Checkout failed: ${errorText}`);
      }

      const { sessionId } = await response.json();
      
      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
      if (!stripe) throw new Error('Stripe failed to load');
      
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) throw error;
    } catch (err) {
      console.error('Subscription error:', err);
      alert(err instanceof Error ? err.message : 'Failed to process subscription. Please try again.');
    } finally {
      setIsLoading(null);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <main className="pt-20">
        <section className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 font-serif">
                Join the largest community of parents fighting against hidden ingredients
              </h1>
              <p className="mt-4 text-xl text-gray-600">
                All plans include a 14-day trial. Cancel anytime.
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className={`relative rounded-2xl ${
                    tier.highlighted
                      ? 'bg-white border-2 border-emerald-500 shadow-xl'
                      : 'bg-white border border-gray-200 shadow-sm'
                  } p-8 transition-transform hover:scale-105`}
                >
                  {tier.highlighted && (
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <span className="inline-flex rounded-full bg-emerald-500 px-4 py-1 text-sm font-semibold text-white">
                        Most Popular
                      </span>
                    </div>
                  )}
                  
                  <div className="text-center">
                    <h3 className="text-2xl font-bold text-gray-900">{tier.name}</h3>
                    <div className="mt-4 flex items-baseline justify-center">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        ${tier.price}
                      </span>
                      <span className="ml-1 text-xl text-gray-500">/month</span>
                    </div>
                    <p className="mt-4 text-sm text-gray-600">{tier.description}</p>
                  </div>

                  <ul className="mt-8 space-y-4">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <Check className="h-5 w-5 text-emerald-500 shrink-0" />
                        <span className="ml-3 text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>

                  <div className="mt-8">
                    <button
                      onClick={() => handleSubscribe(tier.priceId)}
                      disabled={isLoading === tier.priceId}
                      className={`w-full rounded-lg px-4 py-3 text-center font-semibold transition-colors ${
                        tier.highlighted
                          ? 'bg-emerald-600 text-white hover:bg-emerald-700'
                          : 'bg-white text-emerald-600 border-2 border-emerald-600 hover:bg-emerald-50'
                      } ${isLoading === tier.priceId ? 'opacity-75 cursor-not-allowed' : ''}`}
                    >
                      {isLoading === tier.priceId ? 'Processing...' : tier.cta}
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-16 text-center">
              <p className="text-gray-600">
                Questions about our plans? <a href="#contact" className="text-emerald-600 font-medium hover:text-emerald-700">Contact us</a>
              </p>
            </div>
          </div>
        </section>

        <Testimonials />
      </main>
    </div>
  );
}